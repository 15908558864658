import { n as normalizeComponent } from "./vueComponentNormalizer.js";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("edit-modal-composer", { attrs: { "title": "Edit Item Translation", "has-deactivate-button": false, "is-fetch-entity-by-id": false, "has-last-modified": false, "custom-url": _vm.customUrl } }, [_c("select-input", { key: "procSpecId", attrs: { "label": "Procurement Specialist", "placeholder": "Type Procurement Specialist", "reference": "web-analytics.common.ppv-procurement-specialist", "source": "procSpecId", "source-label": "procurementSpecialist", "span": 24, "rules": "required", "form-item": "" } }), _c("select-input", { key: "gPortfolioId", attrs: { "label": "Global Portfolio", "placeholder": "Type Global Portfolio", "reference": "web-analytics.common.ppv-global-portfolio", "source": "gPortfolioId", "source-label": "gPortfolio", "span": 24, "rules": "required", "form-item": "" } })], 1);
};
var staticRenderFns$1 = [];
var EditItemTranslations_vue_vue_type_style_index_0_lang = /* @__PURE__ */ (() => "")();
const apiUrl$1 = "#{VUE_APP_API_URL}#";
const __vue2_script$1 = {
  name: "EditItemTranslations",
  inject: ["crud"],
  data() {
    return {
      apiUrl: apiUrl$1,
      country: this.crud.getQueryString("CountryID").value,
      customUrl: `${apiUrl$1}/web-analytics/item-translations`
    };
  },
  created() {
    this.storeValue("countryId", this.country);
  },
  methods: {
    storeValue(resourceKey, value) {
      this.editForm = {
        ...this.editForm,
        [resourceKey]: value
      };
      this.crud.updateEntity("editForm", {
        resourceKey,
        value
      });
    }
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, null, null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var EditItemTranslations = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", [_c("resource", { attrs: { "api-url": _vm.apiUrl, "name": "web-analytics.common.ppv-procurement-specialist" } }), _c("resource", { attrs: { "api-url": _vm.apiUrl, "name": "web-analytics.common.ppv-global-portfolio" } }), _c("resource", { attrs: { "api-url": _vm.apiUrl, "name": "web-analytics.item-translations", "redirect-route": "/web-analytics/item-translations" } }, [_c("edit-item-translations")], 1)], 1);
};
var staticRenderFns = [];
var index_vue_vue_type_style_index_0_lang = /* @__PURE__ */ (() => "")();
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  components: {
    EditItemTranslations
  },
  data() {
    return {
      apiUrl,
      EditItemTranslations
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
